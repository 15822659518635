import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns = ({
  location,
}) => {
  const title = "過去リツイートキャンペーン参加者リスト作成は可能？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoTを利用せずに実施したTwitterのリツイートキャンペーン(インスタントウィン)があります。このキャンペーンに参加したユーザーリストを取得することはできますか？"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              PARKLoTを利用せずに実施したTwitterのリツイートキャンペーンがあります。このキャンペーンに参加したユーザーを後から取得することはできますか？
            </h1>
            <p className="qa-page__answer">
              お問い合わせください。
              <br />
            </p>
            <p className="qa-page__text">
              Twitterの仕様上、通常は直近の100件までしかリツイートユーザーは取得できません。
              <br />
              ただし、状況によっては別の方法を使ってリツイートユーザーを取得できることもあります。
              <br />
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  専用のツールを利用しなければ、リツイートユーザーは直近100件までしか取得できない
                </h2>

                <p className="qa-page__text">
                  リツイートキャンペーンを実施する場合、特に注意が必要なポイントがこちらです。
                </p>

                <p className="qa-page__text">
                  リツイートユーザーをTwitterのアプリから確認しようとしても、実は直近の100名までしか確認することができません。
                </p>

                <h3 className="qa-page__h3">
                  PARKLoTのリツイートユーザー収集の仕組み
                </h3>

                <p className="qa-page__text">
                  PARKLoTではキャンペーン開始直後から終了まで、常にバックグラウンドでシステムが動作してリツイートユーザーのデータを収集し続けています。
                </p>

                <h3 className="qa-page__h3">ツールのご利用は計画的に！</h3>

                <p className="qa-page__text">
                  抽選を実施するときに慌てないためにも、リツイートキャンペーンを実施する際はツールのご利用を検討してください。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns
